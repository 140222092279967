var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-card-actions',{ref:"refreshCard",attrs:{"action-back":"","action-save":"","title":this.currentItem.name,"no-body":"","disable-loading-on-refresh":"","loaded":_vm.loaded},on:{"back":function($event){return _vm.hRedirect('apps-videosnippets-list')},"save":_vm.addItem}}),_c('b-row',[_c('b-col',{staticClass:"mb-2",attrs:{"md":"6"}},[_c('b-card',{staticClass:"h-100",attrs:{"title":_vm.$t('Video Poster')}},[_c('b-alert',{attrs:{"variant":"warning","show":""}},[_c('div',{staticClass:"alert-body"},[_vm._v(" "+_vm._s(_vm.$t('Video poster upload is available after the first save.'))+" ")])])],1)],1),_c('b-col',{staticClass:"mb-2",attrs:{"md":"6"}},[_c('b-card',{staticClass:"h-100",attrs:{"title":_vm.$t('Video')}},[_c('b-alert',{attrs:{"variant":"warning","show":""}},[_c('div',{staticClass:"alert-body"},[_vm._v(" "+_vm._s(_vm.$t('Video upload is available after the first save.'))+" ")])])],1)],1)],1),_c('validation-observer',{ref:"refFormObserver",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('b-card',{attrs:{"title":_vm.$t('General informations')}},[_c('b-form',{on:{"submit":function($event){$event.stopPropagation();$event.preventDefault();return handleSubmit(_vm.onSubmit)},"reset":function($event){$event.preventDefault();return _vm.resetForm.apply(null, arguments)}}},[_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('div',{staticClass:"mb-2"},[_c('b-form-checkbox',{staticClass:"custom-control-success",attrs:{"value":"1","name":"status"},model:{value:(_vm.currentItem.status),callback:function ($$v) {_vm.$set(_vm.currentItem, "status", $$v)},expression:"currentItem.status"}},[_vm._v(" "+_vm._s(_vm.$t('Active'))+" ")])],1),_c('b-form-group',{attrs:{"label":_vm.$t('Video Nugget Name')}},[_c('validation-provider',{attrs:{"name":_vm.$t('Video Nugget Name'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"name","autofocus":"","trim":"","placeholder":_vm.$t('Video Nugget Name')},model:{value:(_vm.currentItem.name),callback:function ($$v) {_vm.$set(_vm.currentItem, "name", $$v)},expression:"currentItem.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]),(Object.keys(_vm.validation).includes('name'))?_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.$t(_vm.validation.name[0])))]):_vm._e()]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":_vm.$t('Start Date')}},[_c('validation-provider',{attrs:{"name":_vm.$t('startdate'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-datepicker',_vm._b({attrs:{"id":"startdate","name":_vm.$t('startdate'),"placeholder":_vm.$t('Choose your start Date')},model:{value:(_vm.currentItem.startdate),callback:function ($$v) {_vm.$set(_vm.currentItem, "startdate", $$v)},expression:"currentItem.startdate"}},'b-form-datepicker',_vm.labels[_vm.locale] || {},false)),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]),(Object.keys(_vm.validation).includes('startdate'))?_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.$t(_vm.validation.startdate[0])))]):_vm._e()]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":_vm.$t('Short Description')}},[_c('validation-provider',{attrs:{"name":_vm.$t('Short Description'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"rows":"6","placeholder":_vm.$t('Short Description')},model:{value:(_vm.currentItem.description),callback:function ($$v) {_vm.$set(_vm.currentItem, "description", $$v)},expression:"currentItem.description"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]),(Object.keys(_vm.validation).includes('description'))?_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.$t(_vm.validation.description[0])))]):_vm._e()]}}],null,true)}),_c('span',[_vm._v(_vm._s(_vm.charactersLeft)+" ")])],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Clients')}},_vm._l((_vm.me.clients),function(option){return _c('b-form-checkbox',{key:option.id,staticClass:"mb-2",attrs:{"value":option.id,"name":"clients"},model:{value:(_vm.currentItem.clients),callback:function ($$v) {_vm.$set(_vm.currentItem, "clients", $$v)},expression:"currentItem.clients"}},[_vm._v(" "+_vm._s(option.name)+" ")])}),1)],1)],1)],1)],1),_c('b-card',[_c('div',{staticClass:"d-flex"},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],attrs:{"variant":"success","type":"submit"},on:{"click":function($event){$event.preventDefault();return _vm.addItem.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.$t('Create Video Nugget'))+" ")])],1)])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }