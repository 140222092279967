<template>
  <div>
    <b-card-actions
        ref="refreshCard"
        action-back
        action-save
        @back="hRedirect('apps-videosnippets-list')"
        @save="addItem"
        :title="this.currentItem.name"
        no-body
        disable-loading-on-refresh
        :loaded.snyc="loaded"
    >
    </b-card-actions>
    <b-row>
      <b-col md="6" class="mb-2">
        <b-card :title="$t('Video Poster')" class="h-100">
          <b-alert variant="warning" show>
            <div class="alert-body">
              {{ $t('Video poster upload is available after the first save.') }}
            </div>
          </b-alert>
        </b-card>
      </b-col>
      <b-col md="6" class="mb-2">
        <b-card :title="$t('Video')" class="h-100">
          <b-alert variant="warning" show>
            <div class="alert-body">
              {{ $t('Video upload is available after the first save.') }}
            </div>
          </b-alert>
        </b-card>
      </b-col>
    </b-row>
    <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
      <b-card :title="$t('General informations')">
        <!-- Form -->
        <b-form class="" @submit.stop.prevent="handleSubmit(onSubmit)" @reset.prevent="resetForm">
          <b-row>
            <b-col md="6">
              <!-- Status -->
              <div class="mb-2">
                <b-form-checkbox v-model="currentItem.status" value="1" name="status" class="custom-control-success">
                  {{ $t('Active') }}
                </b-form-checkbox>
              </div>

              <!-- Name -->
              <b-form-group :label="$t('Video Nugget Name')">
                <validation-provider #default="{ errors }" :name="$t('Video Nugget Name')" rules="required">
                  <b-form-input id="name" v-model="currentItem.name" autofocus trim
                                :placeholder="$t('Video Nugget Name')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small v-if="Object.keys(validation).includes('name')" class="text-danger">{{
                      $t(validation.name[0])
                    }}</small>
                </validation-provider>
              </b-form-group>

              <b-form-group :label="$t('Start Date')">
                <validation-provider #default="{ errors }" :name="$t('startdate')" rules="required">
                  <b-form-datepicker id="startdate" :name="$t('startdate')" :placeholder="$t('Choose your start Date')"
                                     v-model="currentItem.startdate" v-bind="labels[locale] || {}"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small v-if="Object.keys(validation).includes('startdate')" class="text-danger">{{
                      $t(validation.startdate[0])
                    }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group :label="$t('Short Description')">
                <validation-provider #default="{ errors }" :name="$t('Short Description')" rules="required">
                  <b-form-textarea rows="6" v-model="currentItem.description" :placeholder="$t('Short Description')"/>
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small v-if="Object.keys(validation).includes('description')" class="text-danger">{{
                      $t(validation.description[0])
                    }}</small>
                </validation-provider>
                <span>{{ charactersLeft }} </span>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group :label="$t('Clients')">
                <b-form-checkbox v-for="option in me.clients" :key="option.id" v-model="currentItem.clients"
                                 :value="option.id" name="clients" class="mb-2"
                >
                  {{ option.name }}
                </b-form-checkbox>
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
      </b-card>

      <b-card>
        <div class="d-flex">
          <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="success" type="submit"
                    @click.prevent="addItem"
          >
            {{ $t('Create Video Nugget') }}
          </b-button>
        </div>
      </b-card>
    </validation-observer>
  </div>
</template>

<script>
import {
  BAlert,
  BButton,
  BCard,
  BCol,
  BForm,
  BFormCheckbox,
  BFormDatepicker,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BFormTextarea,
  BLink,
  BRow,
  BSidebar,
  BToast
} from 'bootstrap-vue'
import { extend, ValidationObserver, ValidationProvider } from 'vee-validate'
import Ripple from 'vue-ripple-directive'
// eslint-disable-next-line
import 'quill/dist/quill.core.css'
// eslint-disable-next-line
import 'quill/dist/quill.snow.css'
// eslint-disable-next-line
import 'quill/dist/quill.bubble.css'
import { quillEditor } from 'vue-quill-editor'
import VideosnippetFileupload from './forms/VideosnippetFileupload'
import flatPickr from 'vue-flatpickr-component'
import { German } from 'flatpickr/dist/l10n/de.js'
import { max } from 'vee-validate/dist/rules'
import { setDatePickerTranslation } from '@core/mixins/datePickerLocales'
import BCardActions from '@core/components/b-card-actions/BCardActions'
import {required} from '@validations'

extend('max', max)

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BAlert,
    BFormDatepicker,
    BToast,
    BLink,
    BCard,
    BRow,
    BCol,
    BFormCheckbox,
    quillEditor,
    flatPickr,
    BFormTextarea,
    BCardActions,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
    VideosnippetFileupload
  },
  directives: {
    Ripple,
  },

  data() {
    return {
      me: {},
      currentItem: {
        id: '',
        name: '',
        status: 1,
        description: '',
        media: [],
        clients: []
      },
      date: null,
      dateDefault: null,
      timePicker: null,
      dateNtim: null,
      multiDate: null,
      rangeDate: null,
      humanDate: null,
      disableDate: null,
      inlineDate: null,
      loaded: true,
      config: {
        enableTime: true,
        wrap: true, // set wrap to true only when using 'input-group'
        altFormat: 'd.m.Y H:i',
        altInput: true,
        dateFormat: 'Y-m-d H:i',
        locale: German, // locale for this instance only

      },
      editorOption: {
        modules: {
          toolbar: '#toolbar',
        },
      },
      validation: {},
      validate: false,
    }
  },
  mixins: [setDatePickerTranslation],
  computed: {
    charactersLeft() {
      if (this.currentItem.description) {
        let char = this.currentItem.description.length,
            limit = 300
        return char + ' / ' + limit + ' ' + this.$t('characters remaining')
      }
    }
  },
  methods: {
    addItem() {
      this.$refs.refFormObserver.validate()
          .then((success) => {
            this.validation = {}

            if (success) {
              this.$http.post(`/videosnippets`, this.currentItem)
                  .then(response => {
                    this.$bvToast.toast(this.$i18n.t('Item saved'), {
                      title: this.$i18n.t('Success'),
                      variant: 'success',
                      toaster: 'b-toaster-bottom-right'
                    })
                    this.$router.push({
                      name: 'apps-videosnippets-edit',
                      params: { id: response.data.id }
                    })

                  })
                  .catch(error => {
                    if (error.response.status == 422) {
                      this.validation = error.response.data.errors
                      this.validate = true
                    } else {
                      this.$bvToast.toast(this.$i18n.t(error.response.data.message), {
                        title: this.$i18n.t(error.response.data.message),
                        variant: 'danger',
                        toaster: 'b-toaster-bottom-right',
                      })
                    }

                  })
            }
          })

    },

    getValidationState({
      dirty,
      validated,
      valid = null
    }) {
      return dirty || validated ? valid : null
    },

    resetForm() {
    },
    onSubmit() {
      this.addItem()
    },
    getUploadUrl() {
      return `/videosnippets/${this.$route.params.id}/fileupload`
    },
    getFiles() {
      return this.currentItem.media
    },
    getClients() {
      let data = this.helperGet(`/me`, this.setClients)
    },
    setClients(data) {
      this.me = data
    }

  },
  mounted() {
    this.getClients()
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';

.quill-editor .ql-container {
  height: 250px
}
</style>
